import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import settingsReducer from "../features/settings/settingsSlice";
import usersSlice from "../features/users/usersSlice";
import pdfSlice from "../features/newproductpdf/pdfSlice";
import offerSlice from "../features/products/offerSlice";
import offerProductSlice from "../features/products/offerProductSlice";
import paymentSlice from "../features/payments/paymentSlice";
import orderSlice from "../features/orders/orderSlice";
import cartSlice from "../features/cart/cartSlice";
import productsSlice from "../features/products/productsSlice";
import allergenSlice from "../features/products/allergenSlice";
import ingredientSlice from "../features/products/ingredientSlice";

const store = configureStore({
  reducer: {
    sitesettings: settingsReducer,
    users: usersSlice,
    newproductpdf: pdfSlice,
    offerlist: offerSlice,
    offerproduct: offerProductSlice,
    orders: orderSlice,
    payments: paymentSlice,
    cart: cartSlice,
    products: productsSlice,
    allergens: allergenSlice,
    ingredients: ingredientSlice,
  },
   //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
const useAppDispatch = () => store.dispatch;

export { useAppDispatch };

export default store;
